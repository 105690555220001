<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>Top Applications</b-card-title>
      </div>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <b-form-select id="duration" v-model="filters.period" @change="getTopActivities()">
          <b-form-select-option value="7days">Last 7 Days</b-form-select-option>
          <b-form-select-option value="3months">3 Months</b-form-select-option>
          <b-form-select-option value="6months">6 Months</b-form-select-option>
        </b-form-select>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-horizontal-bar-chart v-if="chartDataReady" v-show="!noData"
                                              ref="topChart"
                                              :height="300"
                                              :data="chartData"
                                              :options="chartjsData.horizontalBarChart.options"
      />

      <b-alert v-show="noData" variant="primary" show>
        <div class="alert-body">
          <span>No activities found for this period</span>
        </div>
      </b-alert>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'

import helperService from '@/services/HelperService';
import OwnersService from '@/services/OwnersService';
import { $themeColors } from '@themeConfig';
import ChartjsComponentHorizontalBarChart from './charts-components/ChartjsComponentHorizontalBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    uid: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartDataReady: false,
      duration: '7days',
      chartjsData,
      noData: false,
      filters: {
        page: 0,
        page_size: 10,
        search: '',
        app: '',
        period: '6months',
        owner_uid: '',
      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: $themeColors.primary,
            borderColor: 'transparent',
            barThickness: 15,
          },
        ],
      },
    }
  },
  mounted() {
    if (this.uid !== '') {
      this.filters.owner_uid = this.uid
    }
    this.getTopActivities();
  },
  methods: {
    async getTopActivities() {
      this.loading = true
      OwnersService.getTopActivities(this.filters).then(res => {
        // CLear the arrays
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
        res.data.data.forEach(item => {
          this.chartData.labels.push(item.description)
          this.chartData.datasets[0].data.push(item.count)
        })
        this.chartDataReady = true
        this.noData = res.data.data.length === 0
      }).catch(err => {
       helperService.showNotfyErr(this.$toast, err, 'Could not get activities, please refresh and try again')
      })
      .finally(() => {
        this.loading = false
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
